import React from 'react';

import { UserState } from '../model/User';
import PageLayout from '../views/Layout/Page';
import CarRegistrationPage from '../views/Vehicle/CarRegistrationPage';

export default function AddCarPage() {
  return (
    <PageLayout private restriction={{ userState: UserState.BLOCKED_PL }}>
      <CarRegistrationPage />
    </PageLayout>
  );
}
