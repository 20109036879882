import React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { DocumentType } from '../../model/Document';
import Tracking from '../../services/Tracking';
import type { ReduxState } from '../../store';
import { actions } from '../../store/carRegistration';
import { SubmitButton } from '../Form/SubmitButton';
import T, { TPrefix } from '../Translate';
import Wizard, { useWizard, type WizardChildProps, WizardStep } from '../Wizard';

import CarRegistrationLayout from './CarRegistrationLayout';
import { Overview, PhotoUpload, requiredPhotoTypes, type WizardContext } from './VehiclePhotos';

export default function CarRegistrationPhotos(_: WizardChildProps) {
  const dispatch: DispatchFunction = useDispatch();
  const { vehicle } = useSelector((store: ReduxState) => ({
    vehicle: store.carRegistration.vehicle,
  }));
  const wizard = useWizard();
  const isValid = useMemo(
    () => requiredPhotoTypes.every(it => !!vehicle.documents.find(d => d.type === it)),
    [vehicle.documents],
  );
  const [showError, setShowError] = useState(false);

  const handleUpload = async (type: DocumentType, dataURI: string) => {
    const document = await dispatch(actions.uploadPhoto(type, dataURI));
    setShowError(false);
    return document.hash;
  };

  const handleRemove = async (hash: string) => {
    await dispatch(actions.removePhoto(hash));
  };

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      if (!isValid) {
        setShowError(true);
        return;
      }

      dispatch(actions.completeStepPhotos());
      wizard.goForward();
    },
    [isValid],
  );

  return (
    <TPrefix name="car.registration">
      <Wizard initialData={{ type: null } as WizardContext}>
        <WizardStep step="photos-overview">
          <CarRegistrationLayout hideBackButton>
            <form onSubmit={handleSubmit}>
              <Overview
                documents={vehicle.documents}
                error={!isValid && showError ? 'photos.error' : null}
                onAddMoreClick={() => Tracking.track('CAR_REG_PHOTOS_ADD_MORE_CLICKED')}
                onRemove={handleRemove}
                pageview="addcar_photos_intro"
                size="sm"
              />
              <SubmitButton className="btn-block mt-5">
                <T id="photos.submit" />
              </SubmitButton>
            </form>
          </CarRegistrationLayout>
        </WizardStep>

        <WizardStep skip={({ type }) => type === null}>
          <CarRegistrationLayout className="" hideCloseLink hideNavigation>
            <PhotoUpload
              layout={({ children }) => <div className="container container--sm">{children}</div>}
              onUpload={handleUpload}
              pageviews={['addcar_photos_shoot', null]}
            />
          </CarRegistrationLayout>
        </WizardStep>
      </Wizard>
    </TPrefix>
  );
}
