import React from 'react';
import { useSelector } from 'react-redux';

import type { ReduxState } from '../../store';
import Switch from '../Switch';
import { TContext } from '../Translate';
import type { WizardChildProps } from '../Wizard';

import CarRegistrationCombustionForm from './CarRegistrationCombustionForm';
import CarRegistrationElectricForm from './CarRegistrationElectricForm';

export default function CarRegistrationTechnicalInfo_2(_: WizardChildProps) {
  const { vehicle } = useSelector((state: ReduxState) => state.carRegistration);

  return (
    <TContext name="short">
      <Switch value={vehicle.is_electric}>
        <CarRegistrationElectricForm case={true} />
        <CarRegistrationCombustionForm case={false} />
      </Switch>
    </TContext>
  );
}
