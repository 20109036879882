import React from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { Form, FormikProvider, useFormik } from 'formik';
import { boolean, number, object, string } from 'yup';

import { usePageView } from '../../hooks/useTracking';
import { ApiError } from '../../services/Api';
import Tracking from '../../services/Tracking';
import type { VehicleRegistration } from '../../services/VehicleRegistration';
import { actions } from '../../store/carRegistration';
import { flashMessageError } from '../../store/session';
import FormikSubmitButton from '../Form/SubmitButton';
import Link from '../Link';
import { useRegion } from '../Restriction';
import T, { TPrefix } from '../Translate';
import { useWizard, type WizardChildProps } from '../Wizard';

import BasicInfoFields from './BasicInfoFields';
import CarRegistrationLayout from './CarRegistrationLayout';
import type { WizardContext } from './CarRegistrationPage';

export default function CarRegistrationBasicInfo(_: WizardChildProps) {
  const [region] = useRegion();
  const dispatch: DispatchFunction = useDispatch();
  const wizard = useWizard<WizardContext>();

  usePageView('addcar_basic');

  const formik = useFormik<VehicleRegistration['verification']>({
    validationSchema: object({
      vin: string().required('global.required'),
      license_plate: string().required('global.required'),
      mileage: number().min(0).required('global.required'),
      country_code: string().required('global.required').oneOf([region]),
      has_own_insurance: boolean().optional(),
    }),
    initialValues: {
      vin: '',
      license_plate: '',
      mileage: 0,
      country_code: region,
      has_own_insurance: false,
    },
    async onSubmit(values) {
      try {
        await dispatch(actions.completeStepBasic(values));
        wizard.goForward({ region: values.country_code });
      } catch (error) {
        if (error instanceof ApiError) {
          error.findAnyMessage();
        }
        dispatch(flashMessageError(error.message));
      }
    },
  });

  return (
    <CarRegistrationLayout hideCloseLink>
      <FormikProvider value={formik}>
        <Form>
          <T as="h1" id="car.registration.basic.title" />
          <TPrefix name="car.registration">
            <BasicInfoFields />
          </TPrefix>

          {!formik.values.has_own_insurance ? (
            <T as="div" className="text-muted mb-2 mt-5" html id="car_registration_basic_info_insurance_agreement" />
          ) : null}
          <FormikSubmitButton
            className={cn('btn-block', {
              'mt-5': formik.values.has_own_insurance,
            })}
            onClick={() => Tracking.track('CAR_REG_CONTINUE_CLICKED')}
          >
            <T id="car.registration.basic.submit" />
          </FormikSubmitButton>

          <Link
            {...wizard.returnRoute}
            className="btn btn-link btn-block text-muted mt-2"
            onClick={() => Tracking.track('CAR_REG_COMPLETE_LATER_CLICKED')}
          >
            <T id="car.registration.summary.continueLater" />
          </Link>
        </Form>
      </FormikProvider>
    </CarRegistrationLayout>
  );
}
