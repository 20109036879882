import React from 'react';
import { Helmet } from 'react-helmet';

import { useTranslation } from '../Translate';

const MetaTags = () => {
  const { t } = useTranslation();
  return (
    <Helmet>
      <title>{`${t('addCar.meta.title')} | HoppyGo.com`}</title>
    </Helmet>
  );
};

export default MetaTags;
