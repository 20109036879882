import React from 'react';
import { useSelector } from 'react-redux';

import { usePageView } from '../../hooks/useTracking';
import Tracking from '../../services/Tracking';
import type { ReduxState } from '../../store';
import Link from '../Link';
import Loader from '../Loader';
import T from '../Translate';
import { useWizard, type WizardChildProps } from '../Wizard';
import WizardNavigation from '../WizardNavigation';

import CarRegistrationChecklist from './CarRegistrationChecklist';

export default function CarRegistrationSummary(_: WizardChildProps) {
  const wizard = useWizard();
  const { loading, checklist } = useSelector((store: ReduxState) => store.carRegistration);

  usePageView('addcar_summary');

  const handleContinue = () => {
    Tracking.track('CAR_REG_CONTINUE_CLICKED');
    wizard.goForward();
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <main className="main-container container container--top container--bottom">
      <WizardNavigation hideBackButton />
      <div className="container container--sm">
        <T as="h1" id="car.registration.summary.title" />
        <T as="p" id="car.registration.summary.description" />
        <div className="bg-light rounded-lg p-4 mb-4">
          <T as="h4" className="h5" id="car.registration.summary.prepare.title" />
          <ul className="list list-primary list__noborder mb-0">
            <T as="li" id="car.registration.summary.prepare.licensePlate" />
            <T as="li" id="car.registration.summary.prepare.mileage" />
            <T as="li" id="car.registration.summary.prepare.photos" />
          </ul>
        </div>
        <T as="h2" className="h3" id="car.registration.summary.checklist.title" />
        <CarRegistrationChecklist checklist={checklist} />
        <button className="btn btn-block btn-primary mt-5" onClick={handleContinue} type="button">
          <T id="car.registration.summary.submit" />
        </button>
        <Link
          {...wizard.returnRoute}
          className="btn btn-link btn-block text-muted mt-2"
          onClick={() => Tracking.track('CAR_REG_COMPLETE_LATER_CLICKED')}
        >
          <T id="car.registration.summary.continueLater" />
        </Link>
      </div>
    </main>
  );
}
