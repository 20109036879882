import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePageView } from '../../hooks/useTracking';
import RouteEnum from '../../RouteEnum';
import type { ReduxState } from '../../store';
import { actions } from '../../store/carRegistration';
import { StaticImage } from '../Image';
import Link from '../Link';
import List, { ListItem } from '../List';
import T from '../Translate';

export default function CarRegistrationDone() {
  const dispatch = useDispatch();
  const { vehicle } = useSelector((store: ReduxState) => store.carRegistration);

  usePageView('addcar_victory');

  const handleClick = () => {
    dispatch(actions.exitCarRegistration());
  };

  return (
    <main className="main-container container container--sm container--top container--bottom">
      <div className="d-flex flex-column align-items-center mb-4">
        <StaticImage alt="Car waiting for approval" name="car_registered" />
      </div>

      <T as="h1" className="text-center" id="car.registration.done.title" />
      <T as="p" className="text-center" id="car.registration.done.description" />

      <List success>
        <ListItem icon="calendar">
          <T id="car.registration.done.list.calendar" />
        </ListItem>
        <ListItem icon="car">
          <T id="car.registration.done.list.benefits" />
        </ListItem>
        <ListItem icon="car">
          <T id="car.registration.done.list.equipments" />
        </ListItem>
        <ListItem icon="car">
          <T id="car.registration.done.list.carDescription" />
        </ListItem>
      </List>

      <Link
        className="btn btn-primary btn-block mt-5"
        onClick={handleClick}
        params={{ hash: vehicle.hash }}
        to={RouteEnum.CARS}
      >
        <T id="car.registration.done.continue" />
      </Link>
    </main>
  );
}
