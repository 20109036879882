import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePageView } from '../../hooks/useTracking';
import { getVehicleParking } from '../../model/VehicleLocation';
import type { ReduxState } from '../../store';
import { actions } from '../../store/carRegistration';
import { TPrefix } from '../Translate';
import { useWizard, type WizardChildProps } from '../Wizard';

import CarRegistrationLayout from './CarRegistrationLayout';
import VehicleAddressForm from './VehicleAddressForm';

export default function CarRegistrationAddress(_: WizardChildProps) {
  const dispatch: DispatchFunction = useDispatch();
  const { vehicle } = useSelector((store: ReduxState) => store.carRegistration);
  const wizard = useWizard();

  usePageView('addcar_location');

  return (
    <CarRegistrationLayout hideBackButton>
      <TPrefix name="car.registration">
        <VehicleAddressForm
          country={vehicle.country.alpha2_code}
          location={getVehicleParking(vehicle.locations)}
          onSubmit={async location => {
            await dispatch(actions.completeStepLocation(location));
            wizard.goForward();
          }}
        />
      </TPrefix>
    </CarRegistrationLayout>
  );
}
