import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePageView } from '../../hooks/useTracking';
import type { PriceType } from '../../model/Price';
import { fetchVehiclePublicPrice } from '../../model/VehicleDetailPrices';
import type { ReduxState } from '../../store';
import { actions } from '../../store/carRegistration';
import { flashMessageError } from '../../store/session';
import { TPrefix } from '../Translate';
import { useWizard, type WizardChildProps } from '../Wizard';

import CarRegistrationLayout from './CarRegistrationLayout';
import PricingForm from './PricingForm';

const TIMEOUT = 1 * 1000;

export default function CarRegistrationPricing(_: WizardChildProps) {
  const dispatch = useDispatch();
  const { vehicle, defaultPrices } = useSelector((store: ReduxState) => ({
    vehicle: store.carRegistration.vehicle,
    defaultPrices: store.carRegistration.defaultPrices,
  }));
  const wizard = useWizard();
  const [price, setPrice] = useState<PriceType>(defaultPrices?.estimated_price);
  const timeout = useRef(null);

  usePageView('addcar_pricelist');

  const handleChange = (amount: number) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(async () => {
      const price = await fetchVehiclePublicPrice(vehicle.hash, amount);
      setPrice(price);
    }, TIMEOUT);
  };

  return (
    <CarRegistrationLayout hideBackButton>
      <TPrefix name="car.registration">
        <PricingForm
          currency={vehicle.country.currency}
          estimatedPrice={price}
          flexibleDiscounts={vehicle.prices.price_list.filter(it => it.min_days !== 0)}
          onPricePerDayChange={handleChange}
          onSubmit={async values => {
            try {
              await dispatch(actions.completeStepPricelist(values));
              wizard.goForward();
            } catch (error) {
              dispatch(flashMessageError(error.message));
            }
          }}
          pricePerDay={defaultPrices.price_per_day}
          pricePerKm={defaultPrices.price_per_km}
        />
      </TPrefix>
    </CarRegistrationLayout>
  );
}
