import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';
import { number, object, string } from 'yup';

import { useEnum } from '../../hooks/useEnum';
import { usePageView } from '../../hooks/useTracking';
import { mapAttributesToValues } from '../../model/Enum';
import type { MyVehicleTechnicalDetail_Combustion } from '../../model/MyVehicle';
import type { Country } from '../../services/Country';
import type { ReduxState } from '../../store';
import { actions } from '../../store/carRegistration';
import { flashMessageError } from '../../store/session';
import FormikSubmitButton from '../Form/SubmitButton';
import type { SwitchChildrenProps } from '../Switch';
import T, { TContext, TPrefix } from '../Translate';
import { useWizard } from '../Wizard';

import CarRegistrationLayout from './CarRegistrationLayout';
import TechnicalInfoCombustionFields from './TechnicalInfoCombustionFields';

export default function CarRegistrationCombustionForm(_: SwitchChildrenProps<boolean>) {
  const dispatch = useDispatch();
  const wizard = useWizard<{ region: Country }>();
  const { vehicle } = useSelector((state: ReduxState) => state.carRegistration);
  const [transmissions] = useEnum('transmission');

  usePageView('addcar_detail_2', 'combustion');

  const formik = useFormik<MyVehicleTechnicalDetail_Combustion>({
    validationSchema: object({
      transmission: string()
        .nullable()
        .required('global.required')
        .oneOf(mapAttributesToValues(transmissions), 'global.required'),
      power: number().required('global.required'),
      engine_size: number().required('global.required'),
      fuel_tank_capacity: number().required('global.required'),
      consumption: number().required('global.required'),
    }),
    initialValues: {
      transmission: vehicle.attributes?.transmission,
      power: vehicle.attributes?.power,
      engine_size: vehicle.attributes?.engine_size,
      fuel_tank_capacity: vehicle.attributes?.fuel_tank_capacity,
      consumption: vehicle.attributes?.consumption,
    },
    async onSubmit(values) {
      try {
        await dispatch(actions.completeStepDetail(values, true));
        wizard.goForward();
      } catch (error) {
        dispatch(flashMessageError(error.message));
      }
    },
  });

  return (
    <TContext name="combustion">
      <CarRegistrationLayout>
        <FormikProvider value={formik}>
          <Form>
            <T as="h1" id="car.registration.technical.title_2" />
            <T as="p" id="car.registration.technical.description" />

            <TPrefix name="car.registration">
              <TechnicalInfoCombustionFields />
            </TPrefix>

            <FormikSubmitButton className="btn-block">
              <T id="car.registration.technical.submit" />
            </FormikSubmitButton>
          </Form>
        </FormikProvider>
      </CarRegistrationLayout>
    </TContext>
  );
}
