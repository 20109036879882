import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';
import { number, object } from 'yup';

import { usePageView } from '../../hooks/useTracking';
import type { MyVehicleTechnicalDetail_Electric } from '../../model/MyVehicle';
import type { Country } from '../../services/Country';
import Tracking from '../../services/Tracking';
import type { ReduxState } from '../../store';
import { actions } from '../../store/carRegistration';
import { flashMessageError } from '../../store/session';
import FormikSubmitButton from '../Form/SubmitButton';
import type { SwitchChildrenProps } from '../Switch';
import T, { TContext, TPrefix } from '../Translate';
import { useWizard } from '../Wizard';

import CarRegistrationLayout from './CarRegistrationLayout';
import TechnicalInfoElectricFields from './TechnicalInfoElectricFields';

export default function CarRegistrationElectricForm(_: SwitchChildrenProps<boolean>) {
  const dispatch = useDispatch();
  const wizard = useWizard<{ region: Country }>();
  const { vehicle } = useSelector((state: ReduxState) => state.carRegistration);

  usePageView('addcar_detail_2', 'electric');

  const formik = useFormik<MyVehicleTechnicalDetail_Electric>({
    validationSchema: object({
      power: number().required('global.required'),
      electric_battery_capacity: number().required('global.required'),
      electric_charging_time: number().required('global.required'),
      electric_range: number().required('global.required'),
    }),
    initialValues: {
      power: vehicle.attributes?.power,
      electric_battery_capacity: vehicle.attributes?.electric_battery_capacity,
      electric_charging_time: vehicle.attributes?.electric_charging_time,
      electric_range: vehicle.attributes?.electric_range,
    },
    async onSubmit(values) {
      try {
        await dispatch(actions.completeStepDetail(values, true));
        wizard.goForward();
      } catch (error) {
        dispatch(flashMessageError(error.message));
      }
    },
  });

  return (
    <TContext name="electric">
      <CarRegistrationLayout>
        <FormikProvider value={formik}>
          <Form>
            <TPrefix name="car.registration">
              <T as="h1" id="technical.title_2" />
              <T as="p" id="technical.description" />

              <TechnicalInfoElectricFields />

              <T as="p" id="technical.bottomNote" />

              <FormikSubmitButton className="btn-block" onClick={() => Tracking.track('CAR_REG_CONTINUE_CLICKED')}>
                <T id="technical.submit" />
              </FormikSubmitButton>
            </TPrefix>
          </Form>
        </FormikProvider>
      </CarRegistrationLayout>
    </TContext>
  );
}
