import React from 'react';
import { useDispatch } from 'react-redux';

import { usePageView } from '../../hooks/useTracking';
import { DocumentType } from '../../model/Document';
import Tracking from '../../services/Tracking';
import { actions } from '../../store/carRegistration';
import CardSideUpload from '../CardSideUpload';
import T from '../Translate';
import Wizard, { useWizard, type WizardChildProps } from '../Wizard';

import CarRegistrationLayout from './CarRegistrationLayout';

function Intro() {
  const wizard = useWizard();
  usePageView('addcar_card_intro');

  const handleContinueClick = () => {
    Tracking.track('CAR_REG_CONTINUE_CLICKED');
    wizard.goForward();
  };

  return (
    <CarRegistrationLayout hideBackButton>
      <T as="h1" id="car.registration.card.intro.title" />
      <T as="p" id="car.registration.card.intro.description" />
      <ol className="icon-ul mb-5">
        <li className="mb-3">
          <i className="icon icon-number circle bordered" data-number={1} />
          <T id="car.registration.card.intro.1" />
        </li>
        <li className="mb-3">
          <i className="icon icon-number circle bordered" data-number={2} />
          <T id="car.registration.card.intro.2" />
        </li>
      </ol>
      <div className="bg-success-light rounded-lg d-flex align-items-center p-3 text-success">
        <i className="icon icon-lock icon-lg mr-3" />
        <T as="p" className="mb-0" id="car.registration.card.intro.note" multiline />
      </div>
      <button className="btn btn-block btn-primary mt-5" onClick={handleContinueClick} type="button">
        <T id="car.registration.card.intro.continue" />
      </button>
    </CarRegistrationLayout>
  );
}

export default function CarRegistrationCard(_: WizardChildProps) {
  const dispatch: DispatchFunction = useDispatch();

  return (
    <Wizard>
      <Intro />
      <CarRegistrationLayout className="" hideCloseLink hideNavigation>
        <CardSideUpload
          confirmButton="car.registration.card.front.confirm"
          confirmDescription="car.registration.card.front.description"
          layout={({ children }) => <div className="container container--sm">{children}</div>}
          onCameraClick={() => Tracking.track('CAR_REG_CARD_CAMERA_SHOOT_CLICKED')}
          onSelectClick={() => Tracking.track('CAR_REG_CARD_FILE_SELECT_OPENED')}
          onUpload={async card => await dispatch(actions.uploadCard(DocumentType.RC_FRONT, card))}
          pageviews={['addcar_card_front', 'addcar_card_front_check']}
          retryButton="car.registration.card.front.retry"
          selectButton="car.registration.card.front.select"
          step="front-card"
          title="car.registration.card.front.title"
          uploadDescription="car.registration.card.front.description"
        />
      </CarRegistrationLayout>
      <CarRegistrationLayout className="" hideCloseLink hideNavigation>
        <CardSideUpload
          confirmButton="car.registration.card.back.confirm"
          confirmDescription="car.registration.card.back.description"
          layout={({ children }) => <div className="container container--sm">{children}</div>}
          onCameraClick={() => Tracking.track('CAR_REG_CARD_CAMERA_SHOOT_CLICKED')}
          onSelectClick={() => Tracking.track('CAR_REG_CARD_FILE_SELECT_OPENED')}
          onUpload={async card => {
            await dispatch(actions.uploadCard(DocumentType.RC_BACK, card));
            await dispatch(actions.completeStepCard());
          }}
          pageviews={['addcar_card_back', 'addcar_card_back_check']}
          retryButton="car.registration.card.back.retry"
          selectButton="car.registration.card.back.select"
          step="back-card"
          title="car.registration.card.back.title"
          uploadDescription="car.registration.card.back.description"
        />
      </CarRegistrationLayout>
    </Wizard>
  );
}
