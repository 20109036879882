import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { type HLocation, useLocation } from '@gatsbyjs/reach-router';

import RouteEnum from '../../RouteEnum';
import type { Country } from '../../services/Country';
import type { ReduxState } from '../../store';
import { actions } from '../../store/carRegistration';
import Loader from '../Loader';
import Wizard from '../Wizard';
import { WizardNavigationProvider } from '../WizardNavigation';

import CarRegistrationAddress from './CarRegistrationAddress';
import CarRegistrationBankAccount from './CarRegistrationBankAccount';
import CarRegistrationBasicInfo from './CarRegistrationBasicInfo';
import CarRegistrationCard from './CarRegistrationCard';
import CarRegistrationDone from './CarRegistrationDone';
import CarRegistrationMetaTags from './CarRegistrationMetaTags';
import CarRegistrationPhotos from './CarRegistrationPhotos';
import CarRegistrationPricing from './CarRegistrationPricing';
import CarRegistrationSummary from './CarRegistrationSummary';
import CarRegistrationTechnicalInfo_1 from './CarRegistrationTechnicalInfo_1';
import CarRegistrationTechnicalInfo_2 from './CarRegistrationTechnicalInfo_2';

export interface WizardContext {
  region: Country;
}

export default function CarRegistrationPage() {
  const dispatch = useDispatch();
  const location = useLocation() as HLocation<{ hash?: string }>;
  const { loading, vehicle, checklist } = useSelector((store: ReduxState) => store.carRegistration);

  useEffect(() => {
    dispatch(actions.start(location.state?.hash));
  }, [location.state?.hash]);

  if (loading) {
    return (
      <main className="main-container container container--top container--bottom">
        <Loader />
      </main>
    );
  }

  return (
    <WizardNavigationProvider
      backButton="car.registration.navigation.back"
      supportModal={{
        noOfFaqs: 14,
        pageview: 'addcar_support',
        size: 'lg',
      }}
      translationPrefix="car.registration"
    >
      <CarRegistrationMetaTags />
      <Wizard
        initialData={{ region: vehicle?.country?.alpha2_code } as WizardContext}
        onBeforeReturn={() => {
          dispatch(actions.exitCarRegistration());
        }}
        returnParams={{ hash: vehicle?.hash, tab: null }}
        returnRoute={RouteEnum.CARS}
      >
        <CarRegistrationSummary step="summary" />
        <Wizard completed={vehicle !== null} step="basic">
          <CarRegistrationBasicInfo />
        </Wizard>
        <Wizard completed={checklist.about_car} step="technical">
          <CarRegistrationTechnicalInfo_1 />
          <CarRegistrationTechnicalInfo_2 />
        </Wizard>
        <CarRegistrationCard completed={checklist.technical_card} step="card" />
        <Wizard completed={checklist.price_list_and_account_number}>
          <CarRegistrationPricing />
          <CarRegistrationBankAccount />
        </Wizard>
        <CarRegistrationAddress completed={checklist.car_address} />
        <CarRegistrationPhotos completed={checklist.car_pictures} />
        <CarRegistrationDone />
      </Wizard>
    </WizardNavigationProvider>
  );
}
