import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePageView } from '../../hooks/useTracking';
import type { ReduxState } from '../../store';
import { actions } from '../../store/carRegistration';
import { TPrefix } from '../Translate';
import { useWizard, type WizardChildProps } from '../Wizard';

import BankAccountForm from './BankAccountForm';
import CarRegistrationLayout from './CarRegistrationLayout';

export default function CarRegistrationBankAccount(_: WizardChildProps) {
  const dispatch: DispatchFunction = useDispatch();
  const { vehicle } = useSelector((store: ReduxState) => store.carRegistration);
  const wizard = useWizard();

  usePageView('addcar_bankaccount');

  return (
    <CarRegistrationLayout onReturnClick={() => wizard.setStep('summary')}>
      <TPrefix name="car.registration">
        <BankAccountForm
          bankAccount={vehicle.attributes?.bank_account}
          onSubmit={async values => {
            await dispatch(actions.completeStepBankAccount(values));
            wizard.goForward();
          }}
          paymentReference={vehicle.attributes?.payment_reference}
        />
      </TPrefix>
    </CarRegistrationLayout>
  );
}
