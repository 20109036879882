import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import Tracking from '../../services/Tracking';
import type { ReduxState } from '../../store';
import Link from '../Link';
import T from '../Translate';
import { useWizard } from '../Wizard';
import WizardNavigation from '../WizardNavigation';

import CarRegistrationChecklist from './CarRegistrationChecklist';

import styles from './carRegistrationLayout.module.scss';

interface Props {
  hideBackButton?: boolean;
  hideSupport?: boolean;
  hideCloseLink?: boolean;
  hideNavigation?: boolean;
  onReturnClick?(): void;
  className?: string;
  children: React.ReactNode;
}

export default function CarRegistrationLayout({
  className,
  hideBackButton,
  hideSupport,
  hideCloseLink,
  hideNavigation,
  onReturnClick,
  children,
}: Props) {
  const { checklist } = useSelector((store: ReduxState) => store.carRegistration);
  const wizard = useWizard();

  return (
    <div className={cn('main-container', styles.container)}>
      <aside className={cn(styles.sidebar)}>
        <CarRegistrationChecklist checklist={checklist} />
      </aside>
      <main className="container container--top container--bottom">
        {!hideNavigation && (
          <WizardNavigation
            hideBackButton={hideBackButton}
            hideSupportModal={hideSupport}
            onReturnClick={onReturnClick ?? (() => wizard.goBack())}
          />
        )}
        <section className={className ?? 'container container--sm'}>
          {children}
          {!hideCloseLink && (
            <Link
              {...wizard.returnRoute}
              className="btn btn-link btn-block text-muted mt-2"
              onClick={() => {
                Tracking.track('CAR_REG_COMPLETE_LATER_CLICKED');
                wizard.onBeforeReturn?.();
              }}
            >
              <T id="car.registration.navigation.completeLater" />
            </Link>
          )}
        </section>
      </main>
    </div>
  );
}
