import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';
import { number, object, string } from 'yup';

import { useEnum } from '../../hooks/useEnum';
import { usePageView } from '../../hooks/useTracking';
import { mapAttributesToValues } from '../../model/Enum';
import type { MyVehicleTechnicalDetail_1 } from '../../model/MyVehicle';
import { getYear } from '../../services/DateTime';
import type { ReduxState } from '../../store';
import { actions } from '../../store/carRegistration';
import { flashMessageError } from '../../store/session';
import FormikSubmitButton from '../Form/SubmitButton';
import T, { TContext, TPrefix } from '../Translate';
import { useWizard, type WizardChildProps } from '../Wizard';

import CarRegistrationLayout from './CarRegistrationLayout';
import TechnicalInfoFields_1 from './TechnicalInfoFields_1';

export default function CarRegistrationTechnicalInfo_1(_: WizardChildProps) {
  const dispatch = useDispatch();
  const { vehicle } = useSelector((state: ReduxState) => state.carRegistration);
  const [fuelTypes] = useEnum('fuel');
  const [bodyTypes] = useEnum('type');
  const wizard = useWizard();

  usePageView('addcar_detail_1');

  const formik = useFormik<MyVehicleTechnicalDetail_1>({
    validationSchema: object({
      manufacturer_id: number().nullable().required('global.required'),
      model_id: number().nullable().required('global.required'),
      price: number().required('global.required'),
      manufacture_year: number().required('global.required'),
      total_weight: number().required('global.required'),
      type: string().nullable().required('global.required').oneOf(mapAttributesToValues(bodyTypes), 'global.required'),
      seats: number().required('global.required'),
      fuel: string().nullable().required('global.required').oneOf(mapAttributesToValues(fuelTypes), 'global.required'),
    }),
    initialValues: {
      manufacturer_id: vehicle.manufacturer_id,
      model_id: vehicle.model_id,
      price: vehicle.attributes.custom_price ?? 0,
      manufacture_year: getYear(vehicle.attributes.manufacturing_year ?? new Date()),
      total_weight: vehicle.attributes.total_weight ?? 0,
      type: vehicle.type,
      seats: vehicle.attributes.seats ?? 5,
      fuel: vehicle.fuel,
    },
    async onSubmit(values) {
      try {
        await dispatch(actions.completeStepDetail(values));
        wizard.goForward();
      } catch (error) {
        dispatch(flashMessageError(error.message));
      }
    },
  });

  return (
    <CarRegistrationLayout hideBackButton>
      <FormikProvider value={formik}>
        <Form>
          <TContext name="short">
            <T as="h1" id="car.registration.technical.title_1" />
            <T as="p" id="car.registration.technical.description" />

            <TPrefix name="car.registration">
              <TechnicalInfoFields_1 />
            </TPrefix>

            <FormikSubmitButton className="btn-block">
              <T id="car.registration.technical.submit" />
            </FormikSubmitButton>
          </TContext>
        </Form>
      </FormikProvider>
    </CarRegistrationLayout>
  );
}
